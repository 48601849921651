
import { FC, useState, useEffect } from 'react';
import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CancelIcon from '@mui/icons-material/Delete';
import { DeclineAttempt } from 'src/models/declineAttempt';

interface DeclineAttemptSectionProps {
    declineAttempt: Partial<DeclineAttempt>
    position:number
    onRemoveDeclineAttempt:(position:number) => void
    onChangeDeclineAttempt:(position:number, declineAttempt:Partial<DeclineAttempt>) => void
}

const DeclineAttemptSection: FC<DeclineAttemptSectionProps> = ({ declineAttempt, position, onRemoveDeclineAttempt, onChangeDeclineAttempt }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [rebillsEnabled, setRebillsEnabled] = useState(false)

    useEffect(() => {
    }, [declineAttempt])

    return (
        <>
            <Grid container spacing={1} columns={{ xs: 1, sm: 2 }} mb={1}>
                <Grid item xs={2}>
                    <Divider />
                </Grid>
                    <Grid item xs={position > 1 ? 1 : 2}>
                        <Typography variant='h6'>Decline Attempt {position} (id: {declineAttempt.id})</Typography>
                    </Grid>
                    {position > 1 && <Grid item xs={1}>
                        <LoadingButton
                            loadingPosition='start'
                            startIcon={<CancelIcon />}
                            variant='outlined'
                            color='error'
                            size='small'
                            onClick={() => onRemoveDeclineAttempt(position)}
                        >Remove Decline Attempt
                        </LoadingButton>
                    </Grid>}
                    <Grid item xs={1}>
                        <TextField
                            label="Sublytics Campaign ID"
                            value={declineAttempt.external_campaign_id}
                            type='number'
                            onChange={(e) => {
                                declineAttempt.external_campaign_id = e.target.value
                                onChangeDeclineAttempt(position, declineAttempt)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Sublytics Offer ID"
                            value={declineAttempt.external_offer_id}
                            type='number'
                            onChange={(e) => {
                                declineAttempt.external_offer_id = e.target.value
                                onChangeDeclineAttempt(position, declineAttempt)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Hours after last decline"
                            value={declineAttempt.hours_after_last_decline}
                            type='number'
                            onChange={(e) => {
                                declineAttempt.hours_after_last_decline = e.target.value
                                onChangeDeclineAttempt(position, declineAttempt)
                            }}
                            fullWidth
                        />
                    </Grid>
            </Grid>
        </>
    )
}

export default DeclineAttemptSection;

