import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  CardHeader,
  Link as MuiLink,
  Backdrop,
  styled,
  CircularProgress,
  Button,
  IconButton,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { PaginatedState } from 'src/models/paginatedState';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Page } from 'src/models/page';
import CheckedIcon from '@mui/icons-material/CheckCircleOutline';
import UncheckedIcon from '@mui/icons-material/ErrorOutline';
import { PaginatedResponse } from 'src/models/paginatedResponse';

interface PagesTableProps {
  className?: string
  paginatedResponse?: PaginatedResponse<Page>
  paginatedState: PaginatedState
  isRefreshing:boolean
  onPageChange:(page:number) => void
  onRowsPerPageChange:(rowsPerPage:number) => void
  onEditPage:(page:Page) => void
  onDeactivatePage:(page:Page) => void
  onActivatePage:(page:Page) => void
}

const LimitedBackdrop = styled(Backdrop)(
  () => `
    position: absolute;
    z-index: 1;
`
);

const PagesTable: FC<PagesTableProps> = ({
  paginatedResponse, 
  paginatedState,
  isRefreshing = false, 
  onPageChange, 
  onRowsPerPageChange,
  onEditPage,
  onDeactivatePage,
  onActivatePage,
}) => {
  const navigate = useNavigate()

  const handlePageChange = async (event: any, newPage: number) => {
    onPageChange(newPage)
  };

  const handleLimitChange = async (event: ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(parseInt(event.target.value))
  };

  return (
    <Card sx={{
      position:'relative'
    }}>
      <LimitedBackdrop open={isRefreshing}><CircularProgress size={'5rem'} /></LimitedBackdrop>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Template</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedResponse?.data.map((item) => {
              return (
                <TableRow
                  key={item.id}
                  >
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.id}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.domain?.host ?? 'NO DOMAIN SELECTED'}/{item.path}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.template?.name ?? 'NO TEMPLATE SELECTED'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box display='flex'>
                    <LoadingButton
                      onClick={() => {
                        onEditPage(item)
                      }}
                    >Edit</LoadingButton>
                    <LoadingButton
                      color={ item.active ? 'error' : 'success'}
                      onClick={() => {
                        if(item.active){
                          onActivatePage(item)
                        }else{
                          onDeactivatePage(item)
                        }
                      }}
                    >{ item.active ? 'Deactivate' : 'Activate'}</LoadingButton>

                    </Box>
                  </TableCell>

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={paginatedResponse?.total ?? 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={paginatedState.page}
          rowsPerPage={paginatedState.per_page}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default PagesTable;
