import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { PaginatedState } from 'src/models/paginatedState';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { ProductsService } from 'src/api/services/ProductsService';
import ProductsTable from './ProductsTable';
import { Product } from 'src/models/product';
import ProductDialog from './ProductDialog';
import { DeclineCode } from 'src/models/declineCode';
import NewProductIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';

function ApplicationsProducts() {
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<Product>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<Partial<Product>>({
    name: '',
  })

  const [isSearching, setIsSearching] = useState(false)
  const [serverIP, setServerIP] = useState<string>()

  const onSearch = async (terms:Partial<Product>) => {
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:Partial<Product>) => {
    setSearchTerms(terms)
  }

  const refreshData = async () => {
    setIsSearching(true)
    try{
      const response = await ProductsService.search({
        ...searchTerms,
      }, paginatedState)
      setPaginatedResponse(response)
    } catch(e){}
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false)
  const [existingProduct, setExistingProduct] = useState<Product>()
  const [isRefreshingProduct, setIsRefreshingProduct] = useState(false)

  const onNewProduct = () => {
    setExistingProduct(undefined)
    setIsProductDialogOpen(true)
  }

  const onEditProduct = (product:Product) => {
    setExistingProduct(product)
    setIsProductDialogOpen(true)
  }

  const onProductDialogClose = (shouldRefresh:boolean) => {
    setIsProductDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  const onRefreshProduct = async (product:Product) => {
    setIsRefreshingProduct(true)
    const refreshedProduct = await ProductsService.refresh(product)
    if(refreshedProduct){
      await refreshData()
    }
    setIsRefreshingProduct(false)
  }

  const [isProductActivationDialogOpen, setIsProductActivationDialogOpen] = useState(false)

  const onActivateProduct = (product:Product) => {
    setExistingProduct(product)
    setIsProductActivationDialogOpen(true)
  }

  const onDeactivateProduct = (product:Product) => {
    setExistingProduct(product)
    setIsProductActivationDialogOpen(true)
  }

  const onProductActivationDialogClose = (shouldRefresh:boolean) => {
    setIsProductActivationDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            {/*<PageHeader 
              searchTerms={searchTerms} 
              isSearching={isSearching} 
              onSearch={onSearch} 
              onClear={onClear} 
              onNewProduct={onNewProduct} 
            />*/}
            <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<NewProductIcon />}
              variant='contained'
              onClick={() => {
                onNewProduct()
              }}
            >New Product</LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <ProductsTable 
              paginatedResponse={paginatedResponse} 
              paginatedState={paginatedState} 
              isRefreshing={isSearching} 
              onPageChange={onPageChange} 
              onRowsPerPageChange={onRowsPerPageChange}
              onEditProduct={onEditProduct}
              onActivateProduct={onActivateProduct}
              onDeactivateProduct={onDeactivateProduct}
              onRefreshProduct={onRefreshProduct}
              isRefreshingProduct={isRefreshingProduct} 
            />
          </Grid>
        </Grid>
      </Container>
      <ProductDialog 
        isOpen={isProductDialogOpen} 
        onClose={onProductDialogClose} 
        existingProduct={existingProduct} 
        serverIP={serverIP}
      />
    </>
  );
}

export default ApplicationsProducts;
