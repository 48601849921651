
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Alert, Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { OffersService } from 'src/api/services/OffersService';
import { DeclineAttempt } from 'src/models/declineAttempt';
import AddIcon from '@mui/icons-material/AddCircle';
import { Offer } from 'src/models/offer';
import { ApiException } from 'src/models/apiError';
import { DeclineCode } from 'src/models/declineCode';
import { Domain } from 'src/models/domain';
import { Template } from 'src/models/template';
import { App } from 'src/models/app';
import { ChangeEventHandler } from 'react';
import { InternalCampaign } from 'src/models/internalCampaign';
import { nullable } from 'zod';
import { RemoveCircle } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AppsPicker from 'src/components/AppsPicker';

interface OfferDialogProps {
    isOpen: boolean
    existingOffer?: Offer
    onClose: (shouldRefresh: boolean) => void
    domains:Domain[]
    templates:Template[]
    apps:App[]
    internalCampaigns:InternalCampaign[]
}

const OfferDialog: FC<OfferDialogProps> = ({ 
    isOpen = false, 
    existingOffer, 
    onClose,
    domains,
    templates,
    apps,
    internalCampaigns,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>('')

    const [name, setName] = useState<string>('')
    const [path, setPath] = useState<string>('')
    const [domainID, setDomainID] = useState<string>('')
    const [domain, setDomain] = useState<Domain>()
    const [templateID, setTemplateID] = useState<string>('')
    const [template, setTemplate] = useState<Template>()
    const [templateContent, setTemplateContent] = useState<any>()
    const [postbackURL, setPostbackURL] = useState<string>('')
    const [iframePostbackURL, setIframePostbackURL] = useState<string>('')
    const [declineRedirectURL, setDeclineRedirectURL] = useState<string>('')
    const [successRedirectURL, setSuccessRedirectURL] = useState<string>('')
    const [tracking1, setTracking1] = useState<string>('')
    const [tracking2, setTracking2] = useState<string>('')
    const [tracking3, setTracking3] = useState<string>('')
    const [tracking4, setTracking4] = useState<string>('')
    const [tracking5, setTracking5] = useState<string>('')
    const [tracking6, setTracking6] = useState<string>('')
    const [tracking7, setTracking7] = useState<string>('')
    const [tracking8, setTracking8] = useState<string>('')
    const [tracking9, setTracking9] = useState<string>('')
    const [tracking10, setTracking10] = useState<string>('')
    const [tracking11, setTracking11] = useState<string>('')
    const [tracking12, setTracking12] = useState<string>('')
    const [tracking13, setTracking13] = useState<string>('')
    const [tracking14, setTracking14] = useState<string>('')
    const [tracking15, setTracking15] = useState<string>('')
    const [tracking16, setTracking16] = useState<string>('')
    const [tracking17, setTracking17] = useState<string>('')
    const [tracking18, setTracking18] = useState<string>('')
    const [tracking19, setTracking19] = useState<string>('')
    const [tracking20, setTracking20] = useState<string>('')
    const [appIDs, setAppIDs] = useState<string[]>([]);
    const [stepsInternalCampaignIDs, setStepsInternalCampaignIDs] = useState<any>()
    const [stepsDeclineAppIDs, setStepsDeclineAppIDs] = useState<any>()

    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingOffer) {
            setName(existingOffer.name)
            setPath(existingOffer.path)
            setDomainID(existingOffer.domain_id)
            setTemplateID(existingOffer.template_id)
            setPostbackURL(existingOffer.postback_url)
            setIframePostbackURL(existingOffer.iframe_postback_url)
            setDeclineRedirectURL(existingOffer.decline_redirect_url)
            setSuccessRedirectURL(existingOffer.success_redirect_url)
            setTracking1(existingOffer.tracking1)
            setTracking2(existingOffer.tracking2)
            setTracking3(existingOffer.tracking3)
            setTracking4(existingOffer.tracking4)
            setTracking5(existingOffer.tracking5)
            setTracking6(existingOffer.tracking6)
            setTracking7(existingOffer.tracking7)
            setTracking8(existingOffer.tracking8)
            setTracking9(existingOffer.tracking9)
            setTracking10(existingOffer.tracking10)
            setTracking11(existingOffer.tracking11)
            setTracking12(existingOffer.tracking12)
            setTracking13(existingOffer.tracking13)
            setTracking14(existingOffer.tracking14)
            setTracking15(existingOffer.tracking15)
            setTracking16(existingOffer.tracking16)
            setTracking17(existingOffer.tracking17)
            setTracking18(existingOffer.tracking18)
            setTracking19(existingOffer.tracking19)
            setTracking20(existingOffer.tracking20)
            setAppIDs(existingOffer.apps.map(a => `${a.id}`))
            if(existingOffer.template_content){
                setTemplateContent(existingOffer.template_content)
            } else if (existingOffer.template){
                setTemplateContent(existingOffer.template.content)
            }
            const domain = domains.find(d => `${d.id}` == existingOffer.domain_id)
            setDomain(domain)
            const template = templates.find(t => `${t.id}` == existingOffer.template_id)
            setTemplate(template)
            if(existingOffer.steps_internal_campaign_ids){
                setStepsInternalCampaignIDs(existingOffer.steps_internal_campaign_ids)
            }
            if(existingOffer.steps_decline_app_ids){
                setStepsDeclineAppIDs(existingOffer.steps_decline_app_ids)
            }
        } else {
            setName('')
            setPath('')
            setDomainID('')
            setTemplateID('')
            setPostbackURL('')
            setDeclineRedirectURL('')
            setSuccessRedirectURL('')
            setTracking1('')
            setTracking2('')
            setTracking3('')
            setTracking4('')
            setTracking5('')
            setTracking6('')
            setTracking7('')
            setTracking8('')
            setTracking9('')
            setTracking10('')
            setTracking11('')
            setTracking12('')
            setTracking13('')
            setTracking14('')
            setTracking15('')
            setTracking16('')
            setTracking17('')
            setTracking18('')
            setTracking19('')
            setTracking20('')
            setAppIDs([])
            setTemplateContent(undefined)
            setDomain(undefined)
            setTemplate(undefined)
            setStepsInternalCampaignIDs(undefined)
            setStepsDeclineAppIDs(undefined)
        }
        setError('')
    }, [existingOffer])

    const handleOnDelete = async () => {
        setIsLoading(true)
        try{
            await OffersService.delete({
                id: existingOffer.id,
            })
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleOnSave = async () => {
        setIsLoading(true)
        setError('')
        try{
            if (existingOffer) {
                //Edit
                await OffersService.edit({
                    id: existingOffer.id,
                    name,
                    path,
                    domain_id:domainID,
                    template_id:templateID,
                    postback_url:postbackURL,
                    iframe_postback_url:iframePostbackURL,
                    decline_redirect_url:declineRedirectURL,
                    success_redirect_url:successRedirectURL,
                    tracking1,
                    tracking2,
                    tracking3,
                    tracking4,
                    tracking5,
                    tracking6,
                    tracking7,
                    tracking8,
                    tracking9,
                    tracking10,
                    tracking11,
                    tracking12,
                    tracking13,
                    tracking14,
                    tracking15,
                    tracking16,
                    tracking17,
                    tracking18,
                    tracking19,
                    tracking20,
                },appIDs, templateContent, stepsInternalCampaignIDs, stepsDeclineAppIDs)
            } else {
                //Create
                await OffersService.create({
                    name,
                    path,
                    domain_id:domainID,
                    template_id:templateID,
                    postback_url:postbackURL,
                    iframe_postback_url:iframePostbackURL,
                    decline_redirect_url:declineRedirectURL,
                    tracking1,
                    tracking2,
                    tracking3,
                    tracking4,
                    tracking5,
                    tracking6,
                    tracking7,
                    tracking8,
                    tracking9,
                    tracking10,
                    tracking11,
                    tracking12,
                    tracking13,
                    tracking14,
                    tracking15,
                    tracking16,
                    tracking17,
                    tracking18,
                    tracking19,
                    tracking20,
                },appIDs, templateContent, stepsInternalCampaignIDs, stepsDeclineAppIDs)
            }
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleDomainChange = (event: SelectChangeEvent) => {
        const {
          target: { value },
        } = event;
        setDomainID(value)
        const domain = domains.find(d => `${d.id}` == value)
        setDomain(domain)
    }

    const handleTemplateChange = (event: SelectChangeEvent) => {
        const {
          target: { value },
        } = event;
        setTemplateID(value)
        const template = templates.find(t => `${t.id}` == value)
        setTemplate(template)
        if(template){
            setTemplateContent({...template.content})
        }
    }

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>, key:string) => {
        const { target } = event
        console.log(target)
        //const template = templates.find(t => `${t.id}` == value)
        const file = target.files[0];
        const fileReader = new FileReader();
        const name = target.accept.includes('image') ? 'images' : 'videos';

        fileReader.onload = (e) => {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement("canvas");
                const MAX_WIDTH = 800;
                const MAX_HEIGHT = 800;
                let width = img.width;
                let height = img.height;
          
                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
          
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
          
                canvas.toBlob(async (blob) => {
                  const fileType = file.type.split("/")[1];
                  let newFile:File = null;
          
                  if (fileType === "jpeg" || fileType === "jpg") {
                    newFile = new File([blob], file.name, { type: "image/jpeg" });
                  } else if (fileType === "png") {
                    newFile = new File([blob], file.name, { type: "image/png" });
                  } else if (fileType === "gif") {
                    newFile = new File([blob], file.name, { type: "image/gif" });
                  } else {
                    return
                  }
          
                  let content = {...templateContent}
                  if(key.startsWith('variant')){
                    const variantIndex = key.split('_')[1]
                    content['product_variants'][variantIndex]['file'] = await fileToBase64(newFile)
                  } else {
                    content[`${key}_file`] = await fileToBase64(newFile)
                  }
                  setTemplateContent(content)
                }, file.type, 0.8);
            };
            if(typeof e.target.result === 'string'){
                img.src = e.target.result;
            }
        }
        fileReader.readAsDataURL(file);
    }

    async function fileToBase64(file:File):Promise<string|ArrayBuffer> {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = ev => {
            resolve(ev.target.result)
          }
          reader.readAsDataURL(file)
        })
    }
    
    const handleAppsChange = (ids: string[]) => {
        setAppIDs(ids)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{existingOffer ? 'Edit Offer' : 'Create Offer'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 1, sm: 4 }} mb={2}>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            autoFocus
                            label="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={3}>
                        <Grid container spacing={1} columns={{ xs: 2 }} mb={1} >
                            <Grid item xs={1}>
                                <FormControl fullWidth>
                                    <InputLabel>Domain</InputLabel>
                                    <Select
                                        value={domainID}
                                        label="Domain"
                                        onChange={handleDomainChange}
                                    >
                                        {domains.map((domain) => 
                                            <MenuItem key={domain.id} value={domain.id}>{domain.host}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">/</InputAdornment>,
                                    }}
                                    label="Path"
                                    value={path}
                                    onChange={(e) => {
                                        setPath(e.target.value)
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel>Template</InputLabel>
                            <Select
                                value={templateID}
                                label="Template"
                                onChange={handleTemplateChange}
                            >
                                {templates.map((template) => 
                                    <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider />
                { template && <Grid container spacing={1} columns={{ xs: 2 }} mt={1} mb={2}>
                {template.content.map((item) => {
                    const key = item.key
                    let templateContentValue = ''
                    if(templateContent){
                        templateContentValue = templateContent[key] ?? ''
                    }
                    switch(item.type){
                        case 'image':{
                            return <Grid item xs={1} key={key}>
                                <Button component="label" endIcon={<UploadFileIcon />}>
                                {item.label}
                                <input hidden accept="image/*" multiple type="file" onChange={(event)=> handleImageChange(event, key)}/>
                            </Button>
                            { templateContent[key] && templateContent[`${key}_file`] === undefined && <Box component="img" src={`${location.protocol}//${domain.host}/assets/${template.key}/images/${templateContent[key]}`} sx={{
                                width: 'auto',
                                maxHeight: '50px'
                            }} /> }
                            { templateContent[`${key}_file`] && <Box component="img" src={templateContent[`${key}_file`]} sx={{
                                width: 'auto',
                                maxHeight: '50px'
                            }} /> }
                        </Grid>
                        }
                        case 'variants':{
                            const contentVariants = templateContent[key] ?? []
                            return <Grid item xs={2} mb={1} key={key}>
                            <Grid item xs={2}>
                            <Typography mb={1} >
                                Product Variants
                            </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                { contentVariants.map((variant, i) => {
                                    return <Grid container spacing={1} columns={{ xs: 3 }} key={`variant_${i}`}>
                                       <Grid item xs={1}>
                                <TextField
                                label={`Color`}
                                value={variant.color}
                                onChange={(e) => {
                                    let newContent = {...templateContent}
                                    newContent[key][i].color = e.target.value
                                    setTemplateContent(newContent)
                                }}
                                fullWidth
                            /></Grid> 
                                <Grid item xs={1}>
                                <TextField
                                label={`Hex`}
                                value={variant.hex}
                                onChange={(e) => {
                                    let newContent = {...templateContent}
                                    newContent[key][i].hex = e.target.value
                                    setTemplateContent(newContent)
                                }}
                                fullWidth
                            /></Grid> 

                                <Grid item xs={1}>
                                <Button component="label" endIcon={<UploadFileIcon />}>
                                <input hidden accept="image/*" multiple type="file" onChange={(event)=> handleImageChange(event, `variant_${i}`)}/>
                            </Button>
                            { variant.image && variant.file === undefined && <Box component="img" src={`${location.protocol}//${domain.host}/assets/${template.key}/images/${variant.image}`} sx={{
                                width: 'auto',
                                maxHeight: '50px'
                            }} /> }
                            { variant.file && <Box component="img" src={`${variant.file}`} sx={{
                                width: 'auto',
                                maxHeight: '50px'
                            }} /> }
                            </Grid>
                            </Grid>
                           
                            }) }
                            
                            <Button component="label" endIcon={<AddIcon />} onClick={() => {
                                let newContent = {...templateContent}
                                let variants = newContent[key]
                                if(!variants){
                                    newContent[key] = []
                                }

                                newContent[key].push({
                                    color:'',
                                    hex:'',
                                    image:'',
                                })
                                
                                setTemplateContent(newContent)
                            }}>
                                Add Variant
                            </Button>
                            </Grid>
                        </Grid>
                        }
                        case 'children':{
                            const contentChildren = templateContent[key] ?? []
                            return <Grid item xs={2} mb={1} key={key}>
                            <Grid item xs={2}>
                            <Typography mb={1} >
                                {item.label}
                            </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                { contentChildren.map((childContent, i) => {
                                    return <Grid container spacing={1} columns={{ xs: 2 }} key={`children_${i}`}>
                                        {item.children != undefined && Object.keys(item.children).map((childKey, j) => {
                                            const child = item.children[childKey]
                                            return <Grid item xs={1} key={`child_${j}`}>
                                            <TextField
                                            label={child.label}
                                            value={childContent[child.key]}
                                            placeholder={child.placeholder}
                                            onChange={(e) => {
                                                let newContent = {...templateContent}
                                                newContent[key][i][child.key] = e.target.value
                                                setTemplateContent(newContent)
                                            }}
                                            fullWidth
                                        /></Grid> 
                                        })}
                            <Button component="label" endIcon={<RemoveCircle />} onClick={() => {
                                let newContent = {...templateContent}
                                let deals = newContent[key]
                                deals.splice(i, 1)
                                setTemplateContent(newContent)
                            }}>
                                Remove
                            </Button>
                            </Grid>
                            }) }
                            
                            <Button component="label" endIcon={<AddIcon />} onClick={() => {
                                let newContent = {...templateContent}
                                let deals = newContent[key]
                                if(!deals){
                                    newContent[key] = []
                                }

                                const children = (item.children ?? []).map(c => {
                                    var obj = {}
                                    obj[c.key] = ''
                                    return obj
                                })

                                newContent[key].push(children.reduce((accumulator, current) => {
                                    return {...accumulator, ...current};
                                }, {}))
                                
                                setTemplateContent(newContent)
                            }}> 
                                Add
                            </Button>
                            </Grid>
                        </Grid>
                        }
                        case 'text':{
                            return <Grid item xs={1} key={key}>
                                <TextField
                                label={item.label}
                                value={templateContentValue}
                                placeholder={item.placeholder}
                                onChange={(e) => {
                                    let newContent = {...templateContent}
                                    newContent[key] = e.target.value
                                    setTemplateContent(newContent)
                                }}
                                fullWidth
                            /></Grid>
                        }
                        case 'textarea':{
                            return <Grid item xs={2} key={key}>
                                <TextField
                                multiline
                                label={item.label}
                                value={templateContentValue}
                                placeholder={item.placeholder}
                                onChange={(e) => {
                                    let newContent = {...templateContent}
                                    newContent[key] = e.target.value
                                    setTemplateContent(newContent)
                                }}
                                fullWidth
                            /></Grid>
                        }
                        case 'richtext': {
                            return <Grid item xs={2} key={key}>
                                <ReactQuill
                                    value={templateContentValue}
                                    placeholder={item.placeholder}
                                    onChange={(e) => {
                                        let newContent = { ...templateContent }
                                        newContent[key] = e
                                        setTemplateContent(newContent)
                                    }}
                                />
                            </Grid>
                        }
                        case 'checkbox':{
                            let templateContentValue = null
                            if(templateContent){
                                templateContentValue = templateContent[key] ?? null
                            }

                            return <Grid item xs={2} key={key}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox 
                                    checked={templateContentValue != null}
                                    onChange={(e) => {
                                        let newContent = {...templateContent}
                                        newContent[key] = e.target.checked ? '' : null
                                        setTemplateContent(newContent)
                                    }} />} label={item.label} />
                                </FormGroup>
                                {templateContentValue != null && 
                                <TextField
                                label={item.label}
                                value={templateContentValue}
                                placeholder={item.placeholder}
                                onChange={(e) => {
                                    let newContent = {...templateContent}
                                    newContent[key] = e.target.value
                                    setTemplateContent(newContent)
                                }}
                                fullWidth
                                />}
                            </Grid>
                        }
                    }


                })}
                </Grid>}
                <Divider sx={{mb: 1}} />
                <Typography mb={2}>
                    Steps
                </Typography>
                { template && <Grid container spacing={1} columns={{ xs: 1, sm: 4 }} mb={2}>
                { Array.apply(null, {length: template.steps}).map((e, i) => {
                    const step = i+1
                    const key = `step${step}`
                    const title = `Step ${step} Campaign`
                    let stepInternalCampaignID = ''
                    if(stepsInternalCampaignIDs){
                        stepInternalCampaignID = stepsInternalCampaignIDs[key]
                    }

                    let stepDeclineAppIDs = []
                    if(stepsDeclineAppIDs){
                        stepDeclineAppIDs = stepsDeclineAppIDs[key]
                    }

                    return <Grid item xs={1} sm={4} key={i}>
                        <Typography mb={1} variant='body1'>
                            Step {step}
                        </Typography>
                        <FormControl fullWidth sx={{marginBottom:1}}>
                            <InputLabel>{title}</InputLabel>
                            <Select
                                value={stepInternalCampaignID}
                                label={title}
                                onChange={(e) => {
                                    let newStepInternalCampaignIDs = {...stepsInternalCampaignIDs}
                                    newStepInternalCampaignIDs[key] = e.target.value
                                    setStepsInternalCampaignIDs(newStepInternalCampaignIDs)
                                }}
                            >
                                <MenuItem key={0} value={0}>No campaign</MenuItem>
                                {internalCampaigns.map((internalCampaign) => 
                                    <MenuItem key={internalCampaign.id} value={internalCampaign.id}>{internalCampaign.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <AppsPicker
                        apps={apps}
                        existingAppIDs={stepDeclineAppIDs}
                        onAppsChanged={(e) => {
                            let newStepDeclineAppIDs = {...stepsDeclineAppIDs}
                            newStepDeclineAppIDs[key] = e
                            setStepsDeclineAppIDs(newStepDeclineAppIDs)
                        }}
                        title='Decline Apps'
                        />
                    </Grid>
                })}
                </Grid>}
                <Divider sx={{mb: 1}} />
                <Typography mb={1}>
                    CRM Data
                </Typography>
                <Divider />
                <Grid container spacing={1} columns={{ xs: 2, sm: 4 }} mt={1} mb={2}>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking1"
                            value={tracking1}
                            onChange={(e) => {
                                setTracking1(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking2"
                            value={tracking2}
                            onChange={(e) => {
                                setTracking2(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking3"
                            value={tracking3}
                            onChange={(e) => {
                                setTracking3(e.target.value)
                            }}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking4"
                            value={tracking4}
                            onChange={(e) => {
                                setTracking4(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking5"
                            value={tracking5}
                            onChange={(e) => {
                                setTracking5(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking6"
                            value={tracking6}
                            onChange={(e) => {
                                setTracking6(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking7"
                            value={tracking7}
                            onChange={(e) => {
                                setTracking7(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking8"
                            value={tracking8}
                            onChange={(e) => {
                                setTracking8(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking9"
                            value={tracking9}
                            onChange={(e) => {
                                setTracking9(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking10"
                            value={tracking10}
                            onChange={(e) => {
                                setTracking10(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking11"
                            value={tracking11}
                            onChange={(e) => {
                                setTracking11(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking12"
                            value={tracking12}
                            onChange={(e) => {
                                setTracking12(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking13"
                            value={tracking13}
                            onChange={(e) => {
                                setTracking13(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking14"
                            value={tracking14}
                            onChange={(e) => {
                                setTracking14(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking15"
                            value={tracking15}
                            onChange={(e) => {
                                setTracking15(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking16"
                            value={tracking16}
                            onChange={(e) => {
                                setTracking16(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking17"
                            value={tracking17}
                            onChange={(e) => {
                                setTracking17(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking18"
                            value={tracking18}
                            onChange={(e) => {
                                setTracking18(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking19"
                            value={tracking19}
                            onChange={(e) => {
                                setTracking19(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Tracking20"
                            value={tracking20}
                            onChange={(e) => {
                                setTracking20(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Typography mb={1}>
                    Postback
                </Typography>
                <Divider />
                <Grid container spacing={1} columns={{ xs: 1 }} mt={1} mb={2}>
                    <Grid item xs={1}>
                        <TextField
                            label="Postback URL"
                            value={postbackURL}
                            onChange={(e) => {
                                setPostbackURL(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                { window.config.ENABLE_OFFER_IFRAME_POSTBACK === true && <Grid container spacing={1} columns={{ xs: 1 }} mt={1} mb={2}>
                    <Grid item xs={1}>
                        <TextField
                            label="Iframe Postback URL"
                            value={iframePostbackURL}
                            onChange={(e) => {
                                setIframePostbackURL(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>}
                <Typography mb={1}>
                    Decline Redirect
                </Typography>
                <Divider />
                <Grid container spacing={1} columns={{ xs: 1 }} mt={1} mb={2}>
                    <Grid item xs={1}>
                        <TextField
                            label="Decline Redirect URL"
                            value={declineRedirectURL}
                            onChange={(e) => {
                                setDeclineRedirectURL(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Typography mb={1}>
                    Success Redirect
                </Typography>
                <Divider />
                <Grid container spacing={1} columns={{ xs: 1 }} mt={1} mb={2}>
                    <Grid item xs={1}>
                        <TextField
                            label="Success Redirect URL"
                            value={successRedirectURL}
                            onChange={(e) => {
                                setSuccessRedirectURL(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Typography mb={1}>
                    Connected Apps
                </Typography>
                <Divider />
                <Grid container spacing={1} columns={{ xs: 1 }} mt={1} mb={2}>
                    <Grid item xs={1}>
                    <AppsPicker 
                        apps={apps}
                        existingAppIDs={appIDs}
                        onAppsChanged={handleAppsChange}
                    />
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            { error && <>
            <Alert severity="error">
               {error}
            </Alert>
            <Divider />
            </> }
            <DialogActions>
                { existingOffer && <LoadingButton
                    color='error'
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<DeleteIcon />}
                    onClick={handleOnDelete}
                >Delete</LoadingButton> }
                <Box sx={{flex: '1 0 0'}} />
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingOffer ? 'Save' : 'Create Offer'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default OfferDialog;

