import { Box, Button, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import {FC, useEffect, useState} from 'react'
import { ChromePicker } from 'react-color'
import { App } from 'src/models/app';

interface AppsPickerProps {
    apps:App[]
    existingAppIDs:string[],
    onAppsChanged: (ids:string[]) => void,
    title?:string
}

const AppsPicker: FC<AppsPickerProps> = ({
    apps,
    existingAppIDs,
    onAppsChanged,
    title,
}) => {
    const [appIDs, setAppIDs] = useState<string[]>([]);

    useEffect(() => {
        setAppIDs(existingAppIDs ?? [])
    }, [existingAppIDs])

    const handleAppsChange = (event: SelectChangeEvent<typeof appIDs>) => {
        const {
          target: { value },
        } = event;
        // On autofill we get a stringified value.
        const a = typeof value === 'string' ? value.split(',') : value
        setAppIDs(a)
        onAppsChanged(a)
    }

    return (
        <FormControl sx={{
            width:'100%',
        }}>
            <InputLabel id="demo-multiple-chip-label">{ title ?? 'Apps'}</InputLabel>
            <Select
                multiple
                value={appIDs}
                onChange={handleAppsChange}
                input={<OutlinedInput label={ title ?? 'Apps'} />}
                renderValue={(selected) => { 
                    return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                        const app = apps.find(dc => dc.id == parseInt(value))
                        return (
                        <Chip key={value} label={app.name} />
                    )})}
                    </Box>
                )}}
                //MenuProps={MenuProps}
            >
            {apps.map((app) => (
                <MenuItem
                key={app.id}
                value={`${app.id}`}
                >
                {app.name}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    )
}

export default AppsPicker