
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, IconButton, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { InternalCampaign } from 'src/models/internalCampaign';
import { CampaignsService } from 'src/api/services/CampaignsService';
import { Flow } from 'src/models/flow';
import FlowSection from './FlowSection';
import NewFlowIcon from '@mui/icons-material/AddCircle';
import { DeclineProfile } from 'src/models/declineProfile';

interface CampaignDialogProps {
    isOpen: boolean
    existingCampaign?: InternalCampaign
    onClose: (shouldRefresh: boolean) => void
    declineProfiles:DeclineProfile[]
}

const CampaignDialog: FC<CampaignDialogProps> = ({ isOpen = false, existingCampaign, onClose, declineProfiles }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState<string>('')
    const [user, setUser] = useState<string>('')
    const [flows, setFlows] = useState<Partial<Flow>[]>([])

    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingCampaign) {
            console.log(existingCampaign)
            setName(existingCampaign.name)
            setUser(existingCampaign.user)
            setFlows(existingCampaign.flows)
        } else {
            setName('')
            setUser('')
            let flow:Partial<Flow> = {
                external_campaign_id: '',
                external_offer_id: '',
                external_initial_offer_id: '',
                trial_days: '',
                rebill_external_offer_id: '',
                rebill_frequency_days: ''
            }
            setFlows([flow])
        }
    }, [existingCampaign])

    const handleOnSave = async () => {
        setIsLoading(true)
        console.log(flows)
        try{
        if (existingCampaign) {
            //Edit
            await CampaignsService.edit({
                id: existingCampaign.id,
                name,
                user: user,
            }, flows)
        } else {
            //Create
            await CampaignsService.create({
                name,
                user: user,
            }, flows)
        }
        onClose(true)
        }catch(e) {

        }
        setIsLoading(false)
    }

    const handleNewFlow = () => {
        let flow:Partial<Flow> = {
            external_campaign_id: '',
            external_offer_id: '',
            external_initial_offer_id: '',
            trial_days: '',
            rebill_external_offer_id: '',
            rebill_frequency_days: ''
        }
        let newFlows = [...flows, flow]
        setFlows(newFlows)
    };

    const handleRemoveFlow = (position:number) => {
        let newFlows = [...flows]
        newFlows.splice(position-1, 1)
        setFlows(newFlows)
    }

    const handleChangeFlow = (position:number, flow:Flow) => {
        let newFlows = [...flows]
        newFlows[position-1] = flow
        setFlows(newFlows)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{existingCampaign ? 'Edit Product' : 'Create Product'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 1, sm: 2 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            autoFocus
                            label="Product Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Username"
                            value={user}
                            onChange={(e) => {
                                setUser(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                {flows.map((flow, index) => <FlowSection 
                    key={index} 
                    position={index+1} 
                    flow={flow} 
                    onRemoveFlow={handleRemoveFlow} 
                    onChangeFlow={handleChangeFlow}
                    declineProfiles={declineProfiles}
                />)}
                <LoadingButton
                    startIcon={<NewFlowIcon />}
                    variant='contained'                
                    onClick={handleNewFlow}
                >Add Flow</LoadingButton>
            </DialogContent>
            <Divider />
            <DialogActions>
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingCampaign ? 'Save' : 'Create Product'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default CampaignDialog;

